import Vue from "vue";
import App from "@components/App";
import router from "@router/Index";
import store from "@store/Index";
import { sync } from "vuex-router-sync";

sync(store, router);

import Axios from "axios";
import Vuex from "vuex";
import AsyncComputed from "vue-async-computed";
import PersistedState from "vuex-persistedstate";
import Underscore from "underscore";
import Moment from "moment";
import VueMoment from "vue-moment";
import Bootstrap from "bootstrap/dist/js/bootstrap.min";
import Popper from "popper.js";
import jQuery from "jquery";
import Meta from "vue-meta";
import VeeValidate from "vee-validate";
import Message from "vue-flash-message";
import CurrencyFilter from "vue-currency-filter";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.use(VueReCaptcha, {
	siteKey: "6LeQIfMcAAAAAMnAtcXM8860i_NimDkjo7eR6RZi",
	loaderOptions: {
		useRecaptchaNet: true,
	},
});
Vue.use(AsyncComputed);
Vue.use(Vuex);
Vue.use(PersistedState);
Vue.use(VeeValidate);
Vue.use(Underscore);
Vue.use(Meta);
Vue.use(VueMoment);
Vue.use(Message, {
	messageOptions: {
		timeout: 5000,
		important: true,
		pauseOnInteract: true,
	},
});
Vue.use(CurrencyFilter, {
	thousandsSeparator: ".",
	fractionCount: 2,
	fractionSeparator: ",",
});
Vue.use(ElementUI);
Vue.use(CoolLightBox);

Vue.axios = Axios;
Vue.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
Vue.axios.defaults.headers.common["X-CSRF-TOKEN"] = document.head.querySelector(
	'meta[name="csrf-token"]'
).content;
Vue.axios.defaults.headers.common["Authorization"] = store.state.token;
Vue.axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

Vue.prototype.Axios = Axios;
Vue.prototype.Moment = Moment;
Vue.prototype.Underscore = Underscore;

window.$ = jQuery;

router.beforeEach((to, from, next) => {
	$(".modal").modal("hide");

	let path = to.path;
	let isRedirectAvailable = false;
	let isRedirectLanguageAvailable = false;

	path.startsWith("/hr/") || path === "/hr"
		? (isRedirectAvailable = true)
		: (isRedirectAvailable = false);

	if (isRedirectAvailable) {
		path.length === 3 ? router.push("/") : router.push(path.substr(3));
	}

	path === "/en"
		? (isRedirectLanguageAvailable = true)
		: (isRedirectLanguageAvailable = false);

	if (isRedirectLanguageAvailable) {
		router.push(path + "/");
	}

	path === "/de"
		? (isRedirectLanguageAvailable = true)
		: (isRedirectLanguageAvailable = false);

	if (isRedirectLanguageAvailable) {
		router.push(path + "/");
	}

	next();
});

Vue.config.productionTip = true;
Vue.config.devtools = true;
Vue.config.debug = true;
Vue.config.silent = true;

new Vue({
	el: "#app",
	router,
	store,
	components: { App },
	template: "<App/>",
});
