export const Default = {
	data: function() {
		return {
			loading: false,
			status: "",
			message: "",
		};
	},
	methods: {
		getBlock: function(block) {
			return this.data.page.blocks.find((item) => item.widgets === block);
		},
		getBlockItem: function(block) {
			return this.item.blocks.find((item) => item.widgets === block);
		},
		getNewsBlock(block, id) {
			const single = this.data.news.find((item) => item.id === id);
			return this.data.news[this.data.news.indexOf(single)].blocks.find(
				(item) => item.widgets === block
			);
		},
		getProjectsBlock(block, id) {
			const single = this.data.projects.find((item) => item.id === id);
			return this.data.projects[this.data.projects.indexOf(single)].blocks.find(
				(item) => item.widgets === block
			);
		},
		getChildBlock: function(block, childId) {
			let blok = this.data.children.find((bl) => bl.id === childId);
			return blok.blocks.find((item) => item.widgets === block);
		},
		getBlocks: function(block) {
			return this.data.page.blocks.filter((item) => item.widgets === block);
		},
		getBlockTranslation: function(data, label) {
			return data.translations[this.locality][label]
				? data.translations[this.locality][label]
				: "";
		},
		getTranslation: function(item, label, show = true) {
			return this.data &&
				item.translations[this.data.settings.locality] &&
				item.translations[this.data.settings.locality][label]
				? item.translations[this.data.settings.locality][label]
				: show
				? label
				: "";
		},
		getSnippetTranslation: function(item, label) {
			return item.translations[this.data.settings.locality].translation;
		},
		getContent: function(data, label) {
			return data.content[label] ? data.content[label] : "";
		},
		getSnippets: function(label) {
			let snippet = this.data.snippets.find(
				(item) => item.content.code_label === label
			);
			return snippet !== undefined
				? this.getSnippetTranslation(snippet, label)
				: label;
		},
		getHome: function(translatable, locality) {
			return translatable ? "/" + locality + "/" : "/";
		},
		getPath: function(data) {
			return "/" + data;
		},
		getSlug: function() {
			return this.data.slugs[this.data.settings.locality].slug;
		},
		getFeatured: function(data) {
			return this.data.featured[data];
		},
		setMessage: function(message, status) {
			this.flash(message, "alert alert-" + status + " notification");
		},
		setCookies: function(data) {
			this.$store.dispatch("setCookies", data);
		},
		setOverflow: function() {
			document.body.classList.remove("overflow-hidden");
		},
		setWindowTop: function() {
			window.scrollTo(0, 0);
		},
		makeDate(time) {
			let date = new Date(time * 1000);
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();

			return `${day}.${month}.${year}.`;
		},
		setMeta: async function() {
			const author = document.head.querySelector("[name='author']");
			const description = document.head.querySelector("[name='description']");
			const keywords = document.head.querySelector("[name='keywords']");

			const ogUrl = document.head.querySelector("[property='og:url']");
			const ogTitle = document.head.querySelector("[property='og:title']");
			const ogDescription = document.head.querySelector(
				"[property='og:description']"
			);
			const ogImage = document.head.querySelector("[property='og:image']");

			const twitterCreator = document.head.querySelector(
				"[name='twitter:creator']"
			);
			const twitterSite = document.head.querySelector("[name='twitter:site']");
			const twitterTitle = document.head.querySelector(
				"[name='twitter:title']"
			);
			const twitterDescription = document.head.querySelector(
				"[name='twitter:description']"
			);
			const twitterImage = document.head.querySelector(
				"[name='twitter:image']"
			);

			if (this.getBlock("Search Engine Optimisation")) {
				this.meta.title =
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"title",
						false
					) ?? "Granum Salis";
				this.meta.author =
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"author",
						false
					) ?? "Granum Salis";
				this.meta.keywords =
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"keywords",
						false
					) ?? "";
				this.meta.description =
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"description",
						false
					) ?? "";
				this.meta.facebook_title =
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"facebook_title",
						false
					) ?? "";
				this.meta.facebook_description =
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"facebook_description",
						false
					) ?? "";
				this.meta.image = this.getPath(
					this.getBlock("Search Engine Optimisation").photos.path
				);

				author.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"author",
						false
					) ?? "Granum Salis"
				);
				description.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"description",
						false
					) ?? "Granum Salis"
				);
				keywords.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"keywords",
						false
					) ?? ""
				);

				ogTitle.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"facebook_title",
						false
					) ?? ""
				);
				ogDescription.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"facebook_description",
						false
					) ?? ""
				);
				ogImage.setAttribute(
					"content",
					this.getPath(this.getBlock("Search Engine Optimisation").photos.path)
				);

				twitterCreator.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"author",
						false
					) ?? "Granum Salis"
				);
				twitterTitle.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"facebook_title",
						false
					) ?? "Granum Salis"
				);
				twitterDescription.setAttribute(
					"content",
					this.getTranslation(
						this.getBlock("Search Engine Optimisation"),
						"facebook_description",
						false
					) ?? ""
				);
				twitterImage.setAttribute(
					"content",
					this.getPath(this.getBlock("Search Engine Optimisation").photos.path)
				);
			} else {
				this.meta.title = "Granum Salis";
				this.meta.author = "Granum Salis";
				this.meta.keywords = "";
				this.meta.description = "";
				this.meta.facebook_title = "";
				this.meta.facebook_description = "";
				this.meta.image = "/app/images/static/placeholder.jpg";

				author.setAttribute("content", "Granum Salis");
				description.setAttribute("content", "Granum Salis");
				keywords.setAttribute("content", "");

				ogTitle.setAttribute("content", "");
				ogDescription.setAttribute("content", "");
				ogImage.setAttribute("content", "");

				twitterCreator.setAttribute("content", "Granum Salis");
				twitterTitle.setAttribute("content", "Granum Salis");
				twitterDescription.setAttribute("content", "");
				twitterImage.setAttribute(
					"content",
					"/app/images/static/placeholder.jpg"
				);
			}
			this.meta.locality = this.data.settings.locality;
			this.meta.url = window.location.href;

			ogUrl.setAttribute("content", window.location.href);
			twitterSite.setAttribute("content", window.location.href);
		},
		setLoading: function(data) {
			if (data) {
				this.$store.dispatch("setLoading", data);
			} else {
				setTimeout(() => {
					this.$store.dispatch("setLoading", data);
				}, 1000);
			}
		},
		setModal: function() {
			$(".modal").modal("hide");
		},
		setPlaceholder: function(event) {
			event.target.src = "/app/images/static/placeholder.jpg";
		},
	},
};
