import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Index from '@components/App/Index';

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '*',
            name: 'Index',
            component: Index
        },
    ]
});
