<template>
    <div class="application">
        <Preloader/>
        <flash-message :outerClass="'notifications'"/>
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
    /* Components */
    import Preloader from '@components/App/Partials/Preloader';

    /* Logic */
    export default {
        name: 'App',
        components: {
            Preloader
        }
    }
</script>
