<template>
  <div class="cookie-policy" :class="{ 'd-none': $store.state.cookies }">
    <div class="cookie-policy-wrapper">
      <div class="cookie-text">
        <span>{{ getSnippets('Cookies') }}</span>
      </div>
      <button type="button" class="cookie-button" @click="setCookies(true)">
        <span class="line line1"></span>
        <span class="line line2"></span>
      </button>
    </div>
  </div>
</template>

<script>
/* Mixins */
import {Default} from '@mixins/Default';

/* Logic */
export default {
  name: 'Cookies',
  mixins: [Default],
  props: [
    'data'
  ]
}
</script>
