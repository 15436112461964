/* Import defaults */
import Vue from 'vue'

/* Import packages */
import Vuex from 'vuex'
import PersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

/* Assign defaults */
Vue.use(Vuex)
const ls = new SecureLS({ isCompression: true });

export default new Vuex.Store({
	strict: false,
	plugins: [
		PersistedState({
			key: 'dellure.com',
			// storage: {
			//   getItem: (key) => ls.get(key),
			//   setItem: (key, value) => ls.set(key, value),
			//   removeItem: (key) => ls.remove(key),
			// }
		})
	],
	state: {
		mct: '',
		email: '',
		name: '',
		discount: '',
		coupon: [],
		token: '',
		payment: 'card-payment',
		delivery: [],
		client: [],
		cart: [],
		wish: [],
		cookies: false,
		loading: false,
		authentication: false,
		expiration: null,
	},
	mutations: {
		setMct (state, data) {
			state.mct = data
		},
		setEmail (state, data) {
			state.email = data
		},
		setName (state, data) {
			state.name = data
		},
		setDiscount (state, data) {
			state.discount = data
		},
		setCoupon (state, data) {
			state.coupon = data
		},
		setToken (state, data) {
			state.token = 'Bearer ' + data
			data ? state.authentication = true : state.authentication = false
		},
		setPayment (state, data) {
			state.payment = data
		},
		setDelivery (state, data) {
			state.delivery = data
		},
		setClient (state, data) {
			state.client = data
		},
		setCart (state, data) {
			state.cart = data
		},
		setWish (state, data) {
			state.wish = data
		},
		setCookies (state, data) {
			state.cookies = data
		},
		setLoading (state, data) {
			state.loading = data
		},
		setAuthentication (state, data) {
			state.authentication = data
		},
		setExpiration (state, data) {
			state.expiration = data
		},
		reinitializeData (state, data) {
			state.mct = ''
			state.email = ''
			state.name = ''
			state.discount = ''
			state.coupon = []
			state.token = ''
			state.delivery = []
			state.client = []
			state.cart = []
			state.wish = []
			state.cookies = false
			state.loading = false
			state.payment = 'card-payment'
			state.authentication = false
			state.expiration = new Date()
		},
		reinitializeApplication (state) {
			state.mct = ''
			state.email = ''
			state.name = ''
			state.discount = ''
			state.coupon = []
			state.token = ''
			state.delivery = []
			state.client = []
			state.cart = []
			state.wish = []
			state.cookies = false
			state.loading = false
			state.payment = 'card-payment'
			state.authentication = false
			state.expiration = new Date()
		}
	},
	actions: {
		setMct ({commit}, data) {
			commit('setMct', data)
		},
		setEmail ({commit}, data) {
			commit('setEmail', data)
		},
		setName ({commit}, data) {
			commit('setName', data)
		},
		setDiscount ({commit}, data) {
			commit('setDiscount', data)
		},
		setCoupon ({commit}, data) {
			commit('setCoupon', data)
		},
		setToken ({commit}, data) {
			commit('setToken', data)
		},
		setPayment ({commit}, data) {
			commit('setPayment', data)
		},
		setDelivery ({commit}, data) {
			commit('setDelivery', data)
		},
		setClient ({commit}, data) {
			commit('setClient', data)
		},
		setCart ({commit}, data) {
			commit('setCart', data)
		},
		setWish ({commit}, data) {
			commit('setWish', data)
		},
		setCookies ({commit}, data) {
			commit('setCookies', data)
		},
		setLoading ({commit}, data) {
			commit('setLoading', data)
		},
		setAuthentication ({commit}, data) {
			commit('setAuthentication', data)
		},
		setExpiration ({commit}, data) {
			commit('setExpiration', data)
		},
		reinitializeData ({commit}) {
			commit('reinitializeData')
		},
		reinitializeApplication ({commit}) {
			commit('reinitializeApplication')
		}
	}
})
