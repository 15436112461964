<template>
	<div class="wrapper">
		<header>
			<Topbar :class="{ hidden: scrollTop }" />
			<Navigation :data="data" :nav="nav" v-if="ready" />
		</header>
		<main class="main">
			<div class="c-search-wrapper">
				<div class="c-search-input-field">
					<input
						type="text"
						class="c-search-input"
						:placeholder="getSnippets('Search')"
						v-model="searchInput"
						@change="findResaults()"
					/>
					<span class="btn-search" @click="findResaults()"
						><i class="fas fa-search"></i
					></span>
				</div>
				<div class="c-search-results" v-if="resVisible">
					<div class="c-loading" v-if="localLoader">
						<Loading />
					</div>
					<div v-for="item in serachResults" :key="item" class="c-resault">
						<a :href="item.slug">{{ item.translations.title }}</a>
						<div class="res-badge">
							{{ getBadgeTitle(item.category.title) }}
						</div>
					</div>
				</div>
				<div class="c-search-results" v-if="showNoResults">
					<div class="c-resault">
						{{ getSnippets("No results") }}
					</div>
				</div>
			</div>
			<Component :is="instance" :data="data" v-if="ready" />
			<Footer :data="data" v-if="ready" />
			<Cookies :data="data" v-if="ready" />
		</main>
	</div>
</template>

<script>
	/* Mixins */
	import { Default } from "@mixins/Default";

	/* Components */
	import Navigation from "@components/App/Partials/Navigation";
	import Topbar from "@components/App/Partials/Topbar";
	import Footer from "@components/App/Partials/Footer";
	import Cookies from "@components/App/Partials/Cookies";
	import Loading from "@components/App/Partials/Loading";

	/* Logic */
	export default {
		name: "Index",
		mixins: [Default],
		components: {
			Navigation,
			Footer,
			Cookies,
			Topbar,
			Loading,
		},
		data: function() {
			return {
				data: {
					navigation: {},
					breadcrumbs: [],
					page: {
						blocks: [],
						category: {},
						content: [],
						photos: [],
						photosExtra: [],
						id: null,
						slug: "",
						translations: {},
					},
					featured: {},
					settings: {
						currency: "",
						locality: "",
						translatable: false,
						maintenanceMode: false,
						pageNotFound: false,
					},
					slugs: {},
					snippets: [],
				},
				searchInput: "",
				serachResults: [],
				localLoader: false,
				ready: true,
				showNoResults: false,
				meta: {
					title: "",
					author: "",
					keywords: "",
					description: "",
					facebook_title: "",
					facebook_description: "",
					locality: "",
					image: "",
					url: "",
				},
				scrollTop: false,
			};
		},
		methods: {
			getBadgeTitle: function(item) {
				switch (item) {
					case "Projects":
						return this.data.settings.locality === "hr" ? "Projekti" : item;
						break;
					case "News":
						return this.data.settings.locality === "hr" ? "Novosti" : item;
						break;
					case "Services":
						return this.data.settings.locality === "hr" ? "Usluge" : item;
						break;
					case "Members":
						return this.data.settings.locality === "hr" ? "Članovi" : item;
						break;
					default:
						return this.data.settings.locality === "hr" ? "Stranica" : "Page";
				}
			},
			findResaults: function() {
				this.localLoader = true;
				this.serachResults = [];
				this.Axios.get("/api/v1/search", {
					params: {
						searchQuery: this.searchInput,
						translatable: this.data.settings.locality,
						locality: this.data.settings.locality,
					},
				})
					.then((response) => {
						this.serachResults = response.data.content.data;
						this.localLoader = false;
						if (this.serachResults.length > 0) {
							this.showNoResults = false;
						} else {
							this.showNoResults = true;
						}
					})
					.catch(() => {})
					.finally(() => {});
			},
			scrollAction: function(e) {
				window.scrollY > 0 ? (this.scrollTop = true) : (this.scrollTop = false);
			},
			setExpiration: function() {
				let current = new Date();
				let expiration = this.$store.state.expiration;
				if (expiration === null) {
					this.$store.dispatch("setExpiration", new Date());
				} else {
					let convertCurrent = this.Moment(current);
					let convertExpiration = this.Moment(expiration);
					let duration = this.Moment.duration(
						convertCurrent.diff(convertExpiration)
					);
					let minutes = duration.asMinutes();
					if (minutes > 180) {
						this.$store.dispatch("reinitializeData");
					}
				}
			},
			fetchItems: function() {
				this.$store.state.loading = true;
				this.Axios.get("/api/v1/init", {
					params: {
						slug: this.$route.params.pathMatch,
						page: this.$route.query.page,
						isPreview: this.$route.query.isPreview,
					},
				})
					.then((response) => {
						console.log("DATA", response.data.content.data);
						this.data = {
							breadcrumbs: response.data.content.data.breadcrumbs,
							navigation: response.data.content.data.navigation,
							page: {
								blocks: response.data.content.data.page.blocks,
								category: response.data.content.data.page.category,
								content: response.data.content.data.page.content,
								photos: response.data.content.data.page.photos,
								photosExtra: response.data.content.data.page.photosExtra,
								id: response.data.content.data.page.id,
								slug: response.data.content.data.page.slug,
								translations: response.data.content.data.page.translations,
							},
							featured: response.data.content.data.featured,
							settings: {
								currency: response.data.content.data.settings.currency,
								locality: response.data.content.data.settings.locality,
								translatable: response.data.content.data.settings.translatable,
								maintenanceMode:
									response.data.content.data.settings.maintenanceMode,
								pageNotFound: response.data.content.data.settings.pageNotFound,
							},
							slugs: response.data.content.data.slugs,
							snippets: response.data.content.data.snippets,
							children: response.data.content.data.children,
							members: response.data.content.data.members,
							services: response.data.content.data.services,
							news: response.data.content.data.news,
							projects: response.data.content.data.projects,
						};
						setTimeout(() => {
							this.$store.state.loading = false;
						}, 2000);
					})
					.catch(() => {
						this.data = {
							breadcrumbs: [],
							navigation: {},
							page: {
								blocks: [],
								category: {},
								content: [],
								photos: [],
								photosExtra: [],
								id: null,
								slug: "",
								translations: {},
							},
							featured: {},
							settings: {
								currency: "",
								locality: "",
								translatable: false,
								maintenanceMode: false,
								pageNotFound: false,
							},
							slugs: {},
							snippets: [],
						};
					})
					.finally(() => {
						this.setMeta();
						this.setExpiration();
					});
			},
		},
		created: function() {
			this.fetchItems();
			this.setOverflow();
			this.setWindowTop();
			window.addEventListener("scroll", this.scrollAction);
		},
		metaInfo: function() {
			return {
				title: this.meta.title,
				htmlAttrs: {
					lang: this.meta.locality,
					amp: false,
				},
				meta: [
					{ name: "author", content: this.meta.author },
					{ name: "keywords", content: this.meta.keywords },
					{ name: "description", content: this.meta.description },
					{ property: "og:title", content: this.meta.facebook_title },
					{ property: "og:site_name", content: this.meta.author },
					{ property: "og:type", content: "website" },
					{ property: "og:url", content: this.meta.url },
					{ property: "og:image", content: this.meta.image },
					{
						property: "og:description",
						content: this.meta.facebook_description,
					},
					{ name: "twitter:card", content: "summary" },
					{ name: "twitter:site", content: this.meta.url },
					{ name: "twitter:title", content: this.meta.title },
					{ name: "twitter:description", content: this.meta.description },
					{ itemprop: "name", content: this.meta.title },
					{ itemprop: "description", content: this.meta.description },
					{ itemprop: "image", content: this.meta.image },
				],
				link: [{ rel: "canonical", href: this.meta.url }],
			};
		},
		computed: {
			instance() {
				if (this.data.settings.maintenanceMode) {
					this.ready = true;
					return () => import("@components/App/Errors/503/Index");
				}

				if (this.data.settings.pageNotFound === true) {
					this.ready = true;
					return () => import("@components/App/Errors/404/Index");
				}

				let category = this.data.page.category.title;
				let multiplicity = this.data.page.category.multiplicity;

				this.ready = true;
				return () =>
					import(
						`@components/App/Categories/${category}/${multiplicity}/Index`
					);
			},
			resVisible() {
				return this.localLoader || this.serachResults.length ? true : false;
			},
		},
	};
</script>

<style scoped>
	.main {
		padding-top: 90px;
	}
</style>
