<template>
	<footer class="footer">
		<div class="container">
			<div class="content">
				<div class="row">
					<div class="col-md-4">
						<div class="headline">
							{{ getSnippets("Information") }}
						</div>
						<ul class="navigation-list">
							<li v-for="item in data.navigation.footer" :key="item.id">
								<router-link :to="{ path: item.slug }">{{
									getTranslation(item, "title")
								}}</router-link>
							</li>
						</ul>
					</div>
					<div class="col-md-4 col-xs-12">
						<a :href="data.settings.locality === 'hr' ? '/certifikati' : '/en/certificates'">
							<img class="cert-img" :src="'/app/images/static/cert.gif'" alt="" loading="lazy" />
						</a>
					</div>
					<div class="col-md-4 social-wrapper">
						<div class="headline">
							{{ getSnippets("Follow us") }}
						</div>
						<div class="socials">
							<ul class="social-list">
								<li>
									<a href="https://www.facebook.com/ZadrugaGS " target="_blank"
										><i class="fab fa-facebook-f fa-fw"></i
									></a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/zadruga_granum_salis/ "
										target="_blank"
										><i class="fab fa-instagram fa-fw"></i
									></a>
								</li>
								<li>
									<a
										href="https://www.linkedin.com/company/zadruga-granum-salis"
										target="_blank"
										><i class="fab fa-linkedin fa-fw"></i
									></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	/* Mixins */
	import { Default } from "@mixins/Default";

	/* Logic */
	export default {
		name: "Footer",
		mixins: [Default],
		props: ["data"],
	};
</script>
<style scoped>
.navigation-list {
	display: flex;
	flex-direction: column;
	margin-top: 5px !important;
}

.navigation-list li{
	margin-bottom: 5px;
}
.cert-img{
	width: 70%;
	margin-bottom: 30px;
}
@media only screen and (min-width: 600px) {
	.cert-img{
		width: 100%;
		margin-bottom: 30px;
	}

	.social-wrapper div {
		text-align: right;
	}
}

</style>
