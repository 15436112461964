<template>
	<nav class="navigation navbar navbar-expand-lg">
		<div class="container">
			<div class="row c-navbar">
				<div class="">
					<div class="navigation-brand">
						<router-link
							:to="{
								path: getHome(
									data.settings.translatable,
									data.settings.locality
								),
							}"
						>
							<img class="nav-logo" src="/logo.svg" alt="" />
						</router-link>
					</div>
				</div>
				<div class="main-nav" v-if="data">
					<div class="desktop-nav">
						<div
							class="nav-item"
							v-for="item in data.navigation.main"
							:key="item.id"
						>
							<router-link
								:to="{ path: item.slug }"
								:exact="item.slug === '/' || item.slug === '/en/'"
							>
								{{ getTranslation(item, "title", false) + " " }}
								<i
									v-if="item.id == 15 || item.id == 19 || item.id == 20"
									class="fas fa-caret-down"
								></i>
							</router-link>
							<div v-if="item.id === 15" class="nav-submenu">
								<a :href="item.slug + '?sectionName=about'"
									>Zadruga Granum Salis</a
								>
								<a :href="item.slug + '?sectionName=mission'">{{
									getSnippets("Mission Vision")
								}}</a>
								<a :href="item.slug + '?sectionName=bussinessData'">{{
									getSnippets("Bdata")
								}}</a>
							</div>
							<div v-if="item.id === 19" class="nav-submenu">
								<a :href="item.slug + '?sectionName=services'">{{
									getSnippets("Services")
								}}</a>
								<a :href="item.slug + '?sectionName=auth'">{{
									getSnippets("Auth")
								}}</a>
							</div>
							<div v-if="item.id === 20" class="nav-submenu">
								<a :href="item.slug + '?sectionName=projects'">{{
									getSnippets("Our projects")
								}}</a>
								<a :href="item.slug + '?sectionName=map'">{{
									getSnippets("Where we work")
								}}</a>
								<a :href="item.slug + '?sectionName=clients'">{{
									getSnippets("Clients")
								}}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="c-menu-right">
					<div
						class="menu-colapse"
						@click="toggleNavigation()"
						:class="[toggle ? 'active' : '']"
					>
						<div class="line"></div>
						<div class="line"></div>
						<div class="line"></div>
					</div>
					<div class="languages">
						<ul>
							<li v-for="item in data.slugs" :key="item.locality">
								<router-link
									:to="{ path: item.slug }"
									:class="{ active: item.locality == data.settings.locality }"
									:exact="item.slug === '/' || item.slug === '/en/'"
								>
									{{ item.locality }}
								</router-link>
							</li>
							<li>
								<div class="c-search" @click="toggleSearch()">
									<i v-if="!search" class="fas fa-search"></i>
									<span v-if="search" class="text-color-2 ">X</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="mobile-menu" :class="[toggle ? 'active' : '']">
			<div class="nav-item" v-for="item in data.navigation.main" :key="item.id">
				<span class="mobile-item">
					<router-link
						:to="{ path: item.slug }"
						:exact="item.slug === '/' || item.slug === '/en/'"
					>
						{{ getTranslation(item, "title", false) + " " }}
					</router-link>
					<span
						class="colapse-sub"
						v-if="item.id == 15 || item.id == 19 || item.id == 20"
						v-on:click="toggleSubmenu($event)"
					>
						<i class="fas fa-caret-down"></i> </span
				></span>

				<div v-if="item.id === 15" class="nav-submenu">
					<a :href="item.slug + '?sectionName=about'">Zadruga Granum Salis</a>
					<a :href="item.slug + '?sectionName=mission'">{{
						getSnippets("Mission Vision")
					}}</a>
					<a :href="item.slug + '?sectionName=bussinessData'">{{
						getSnippets("Bdata")
					}}</a>
				</div>
				<div v-if="item.id === 19" class="nav-submenu">
					<a :href="item.slug + '?sectionName=services'">{{
						getSnippets("Services")
					}}</a>
					<a :href="item.slug + '?sectionName=auth'">{{
						getSnippets("Auth")
					}}</a>
				</div>
				<div v-if="item.id === 20" class="nav-submenu">
					<a :href="item.slug + '?sectionName=projects'">{{
						getSnippets("Our projects")
					}}</a>
					<a :href="item.slug + '?sectionName=map'">{{
						getSnippets("Where we work")
					}}</a>
					<a :href="item.slug + '?sectionName=clients'">{{
						getSnippets("Clients")
					}}</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	/* Mixins */
	import { Default } from "@mixins/Default";

	/* Logic */
	export default {
		name: "Navigation",
		mixins: [Default],
		props: ["data"],
		data: function() {
			return {
				toggle: false,
				nav: null,
				search: false,
			};
		},
		methods: {
			toggleNavigation: function() {
				this.toggle = !this.toggle;
			},
			toggleSearch: function() {
				this.search = !this.search;
				const searchContainer = document.querySelector(".c-search-wrapper");
				const searchInput = document.querySelector(".c-search-input-field");
				const searchResaults = document.querySelector(".c-search-results");
				if (searchContainer.classList.contains("active")) {
					searchContainer.classList.remove("active");
					searchInput.classList.remove("animate");
					searchResaults.classList.remove("animate");
				} else {
					searchContainer.classList.add("active");
					searchInput.classList.add("animate");
					searchResaults.classList.add("animate");
				}
			},
			toggleSubmenu(e) {
				const parent = e.target.closest(".nav-item");
				const child = parent.querySelector(".nav-submenu");

				if (child.classList.contains("collapsed")) {
					child.classList.remove("collapsed");
				} else {
					child.classList.add("collapsed");
				}
			},
		},
	};
</script>

<style scoped>
	nav {
		border-bottom: 1px solid rgb(54, 111, 151);
	}
	.nav-logo {
		height: 90px;
		width: auto;
		padding: 8px 0;
	}
	.nav-item {
		margin-left: 7px;
		margin-right: 7px;
	}
	.main-nav {
		display: flex;
		justify-content: center;
	}
	.desktop-nav {
		display: block;
	}
	.menu-colapse {
		display: none;
		border: 3px solid rgb(54, 111, 151);
		width: 40px;
		height: 40px;
		padding: 7px 6px;
		justify-self: flex-end;
		flex-direction: column;
		justify-content: space-between;
		border-radius: 5px;
		cursor: pointer;
		margin-right: 3px;
	}
	.line {
		transition: all 0.2s linear;
	}
	.active .line:nth-child(1) {
		transform: translateY(8px) rotate(45deg);
	}
	.active .line:nth-child(2) {
		opacity: 0;
	}
	.active .line:nth-child(3) {
		transform: translateY(-8px) rotate(-45deg);
	}
</style>
