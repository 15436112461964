<template>
	<div class="topbar">
		<div class="container">
			<div class="mail">
				<a href="mailto:info@zadrugagranumsalis.hr"
					>info@zadrugagranumsalis.hr</a
				>
			</div>
			<div class="socials">
				<a href="https://www.facebook.com/ZadrugaGS " target="_blank"
					><i class="fab fa-facebook-f fa-fw"></i
				></a>
				<a
					href="https://www.instagram.com/zadruga_granum_salis/ "
					target="_blank"
					><i class="fab fa-instagram fa-fw"></i
				></a>
				<a
					href="https://www.linkedin.com/company/zadruga-granum-salis "
					target="_blank"
					><i class="fab fa-linkedin fa-fw"></i
				></a>
			</div>
		</div>
	</div>
</template>

<script>
	import { Default } from "@mixins/Default";

	/* Logic */
	export default {
		name: "Topbar",
		mixins: [Default],
		data: function() {
			return {
				toggle: false,
				nav: null,
			};
		},
		methods: {},
		created() {},
	};
</script>

<style scoped>
	.topbar {
		height: 40px;
		display: flex;
		flex: 1;
		background: rgb(54, 111, 151);
		position: relative;
		transition: all 0.1s linear;
		align-items: center;
		color: white;
		font-size: 13px;
		font-weight: 400;
		z-index: 500;
	}
	.topbar .container {
		display: flex;
		justify-content: space-between;
	}
	.topbar.hidden {
		margin-top: -40px;
	}
	.socials a {
		padding-left: 7px;
		font-size: 15px;
	}
	.mail {
		padding-left: 15px;
	}
</style>
